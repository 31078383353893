import URI from 'urijs';
import './template/css/app.css';
import './template/css/default.css';
import './template/css/index.css';
import './core/passiveEvent';
import CompatiblePullDownRefresh from './components/pullDownRefresh/pullDownRefresh';
import 'url-polyfill';
import { parsePath } from 'history';
import './core/error';
import * as offlineRuntime from 'offline-plugin/runtime';
import React, {cloneElement} from 'react';

import ReactDOM,{findDOMNode} from 'react-dom';
import router from './core/router';
import history from './core/appHistory';

/*
antd5
//import 'antd/dist/antd.variable.min.css';
//兼容antd4组件开发的antd页面能正常使用 包括样式效果和api 属性等
import 'pcx_addon/antd/compatible-4-5/antd';
 */
import {Modal,Alert,ConfigProvider} from 'antd';
//这个地方依赖加载顺序需要处理
import './pcx_addon/core/api/ui/navigate';
import './pcx_addon/core/api/ui/modal';
import './pcx_addon/core/api/ui/loading';
import './pcx_addon/core/api/Page/initenv';
import './core/api/wxApi';
import './pages/index';

import tabbar from './core/tabbar';
import header from './core/header';
import uniqueId from 'lodash/uniqueId';
import last from 'lodash/last';
import {addOpenerRoute, animationEnd, isX5App} from 'core/utils';
import pageLoader from './core/framework/loader';
import {_formatUrl} from "./core/api/ui/navigate";
import "./core/shell";

var errorCount = 0;
var badgeStyle = null;

header.tabbar = tabbar;
let routes = require('./routes.js');
window.routes = routes;
let mainPagePath = routes[0] ? routes[0].path.replace('/:pagetime?', ''):"";
let rootContainer = document.querySelector('#container');
//在router下记录currentpages  因为navigator中通过这个属性查找已打开页面
let pageInstance = 'page';
window.getCurrentReactPages = function () {
  let pages = [];
  router.currentPages.forEach((page) => {
    pages.push(page);
  });
  return pages;
};

window.getCurrentReactPage = function () {
  return _.last(router.currentPages);
};

window.getCurrentPages = function () {
  let pages = [];
  router.currentPages.forEach((page) => {
    pages.push(page.wxPageDeclaration);
  });
  let currentReactPage = getCurrentReactPage();
  if (currentReactPage && currentReactPage.modals && currentReactPage.modals.length > 0) {
    currentReactPage.modals.forEach((model) => {
      if(model.instance.page){
        pages.push(model.instance.page.wxPageDeclaration);
      }
    });
  }
  return pages;
};


function renderComponent(component, target, id, action, path ,location) {
  let currentPages = router.currentPages;
  let page;
  // 兼容swatchTab情况
  if (action === "POP") {
    page = target[pageInstance];
  } else {
    page = ReactDOM.render(React.createElement(
      CompatiblePullDownRefresh, {renderRoot:target}, component
    ), target).page;
    page.renderRoot = target;
    target.setAttribute('route',page.route);
    page.wxPageDeclaration.route = path.replace(/^\//, "");
    page.wxPageDeclaration.location = location;
    // 页面page的background-color配置在这里处理
    let backgroundColor = page.getPageConfig('backgroundColor');
    target.style.cssText = `background-color: ${backgroundColor};`;
    target[pageInstance] = page;

  }

  page.pageId = id;

  if (action === "PUSH") {
    let currentPage = last(currentPages);

    if (currentPage) {
      let pid = currentPage.pageId;
      if (currentPage.onHide && (typeof currentPage.onHide == "function")) {
        currentPage.onHide();
      }
      page.pagePid = pid;
    } else {
      //首页提前渲染
      target.classList.add('current_page');
    }
    currentPages.push(page);


    /*if (page.onLoad && (typeof page.onLoad == "function")) {
      page.onLoad(page.props.options);
    }*/
    if (page.onShow && (typeof page.onShow == "function")) {
      page.onShow();
    }

    animationEnd(target, function () {
      //console.log("animi end");
      // TODO:提前获取配置信息
      header.show(page.getPageConfig());
      let oldPageContainer = target.parentElement.querySelector('.current_page');
      if (oldPageContainer != null && oldPageContainer != target) {
        oldPageContainer.classList.remove('current_page');
      }
      target.classList.add('current_page');
      target.classList.remove("page-from-right-to-center");

      target.parentElement.classList.remove("page-animationing");
      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
      tabbar.show(path, page.getPageConfig());
    });
    target.classList.add("page-from-right-to-center");
    target.parentElement.classList.add("page-animationing");
  } else if (action === "REPLACE") {
    let currentPage = last(currentPages);
    page.pagePid = currentPage.pagePid;
    currentPages.splice(-1);
    currentPages.push(page);
    // TODO:提前获取配置信息
    header.show(page.getPageConfig());
    tabbar.show(path, page.getPageConfig());
    target.classList.add('current_page');
  } else if (action === "POP") {
    currentPages.splice(-1);
    // currentPages = dropRight(currentPages);
    let currentPage = last(currentPages);
    if (currentPage) {
      page.pagePid = currentPage.pagePid;
      currentPages.splice(-1);
      // currentPages = dropRight(currentPages);
    }
    currentPages.push(page);
    // TODO:提前获取配置信息
    header.show(page.getPageConfig());
    tabbar.show(path, page.getPageConfig());
    target.classList.add('current_page');
  }

}

function createPageContainer(id, src, hidden, external = false) {
  let el = document.getElementById(id);
  if (el) {
    return el;
  }
  if (external) {
    el = document.createElement('iframe');
    el.setAttribute('src', src);
    el.setAttribute('frameborder', "0");
  } else {
    el = document.createElement('div');
  }
  el.classList.add('page_container');
  el.classList.add('WxPage');
  el.setAttribute('id', id);
  el.setAttribute('width', hidden ? "0" : "100%");
  el.setAttribute('height', hidden ? "0" : "100%");
  if (hidden) {
    el.setAttribute('style', 'width:0;height:0;border:0; display:none;')
  }
  rootContainer.appendChild(el);
  return el
}

// Find and render a web page matching the current URL path,
// if such page is not found then render an error page (see routes.json,
// core/router.js)
// listen tabbar route
function render(location, action = "PUSH") {
  let path = location.pathname.replace(/\/[0-9]+$/, '');
  let oldPageContainer = document.querySelector('#container>.current_page');
  let id;
  let pid;
  let newPageContainer;
  return router.resolve(location).then((component) => {
    let currentPage = last(router.currentPages);
    if (!currentPage) {
      action = "PUSH";
    }
    //tabbar.show(path);
    if (action == "POP") {
      // 获取要返回的节点
      let pid = currentPage.pagePid;
      let pagePath = history.location.pathname.replace(/\/[0-9]+$/, '');
      let pages = document.querySelectorAll(`#container > [data-page-path='${pagePath}']`);
      // 兼容手动修改hash之后 historylib认为pop的情况
      if (pages.length == 0) {
        action = "PUSH";
      } else {
        newPageContainer = last(pages);
        if (!newPageContainer) {
          newPageContainer = document.querySelector(`#container > .main_page`);
        }
      }
    }

    if (action == "POP" || action == "REPLACE") {
      // 销毁react节点 销毁current_page
      /**
       *  兼容pc门户热更新场景 需要支持 wx.render(location,"REPLACE")逻辑
       *
       *
       */
      let currentReactInstance = getCurrentReactPage();
      if(window.justep && window.justep.Shell && window.justep.Shell.impl){
        oldPageContainer = document.querySelector(".x-portal-pages .x-contents-content.active [xid='root']")
      }

      if (oldPageContainer && oldPageContainer != newPageContainer) {
        oldPageContainer.parentElement.classList.add("page-animationing");
        oldPageContainer.classList.add("page-from-center-to-right");
        animationEnd(oldPageContainer, function () {
          if (oldPageContainer.parentElement) {
            oldPageContainer.parentElement.classList.remove("page-animationing");
          }

          if(oldPageContainer.getAttribute("xid") != "root"){
            ReactDOM.unmountComponentAtNode(oldPageContainer);
            oldPageContainer.remove();
          }else {
            oldPageContainer.classList.remove("page-from-center-to-right");
            oldPageContainer.classList.remove("current_page");
          }
          let page = newPageContainer[pageInstance];
          if (action == "REPLACE") {
            /*if (page.onLoad && (typeof page.onLoad == "function")) {
              page.onLoad(page.props.options);
            }*/
            if (page?.onShow && (typeof page.onShow == "function")) {
              page.onShow();
            }
            if (page?.onReady && (typeof page.onReady == "function")) {
              page.onReady();
            }
          } else if (action == "POP") {
            if (page?.onShow && (typeof page.onShow == "function")) {
              page.onShow();
            }
          }
        });
      }
    } else if (action == "PUSH" && oldPageContainer) {

      // 兼容swatchTab的情况
      if (tabbar.isTabPage(path)) {
        let tabPagePath = tabbar.getTabPagePath(path);
        let tabPage = document.querySelector(`#container>[data-page-path='${tabPagePath}']`);
        if (tabPage) {
          newPageContainer = tabPage;
          // 销毁react节点 销毁current_page
          let oldPagePath = oldPageContainer.getAttribute("data-page-path");
          if (oldPageContainer && !tabbar.isTabPage(oldPagePath)) {
            ReactDOM.unmountComponentAtNode(oldPageContainer);
            oldPageContainer.remove();
          }
        }
      }
    }

    /*
	 * if(document.querySelectorAll('#container>div').length > 5){ throw new
	 * Error("打开页面层次太深，超过最大深度：5"); }
	 */
    // 维护不同情况的id 和pid
    if (!newPageContainer) {
      //root 模式加载的 是pc门户渲染 需要复用
      if(oldPageContainer && oldPageContainer.getAttribute("xid") == "root"){
        id = oldPageContainer.getAttribute("id");
        newPageContainer = oldPageContainer;
      }else{
        id = uniqueId('page_' + path.replace(/\//g, "_"));
        newPageContainer = createPageContainer(id);
      }
    } else {
      id = newPageContainer.getAttribute('id');
    }
    if (path === mainPagePath) {
      newPageContainer.classList.add('main_page');
    }
    if (tabbar.isTabPage(path)) {
      newPageContainer.classList.add('tab_page');
    }
    newPageContainer.setAttribute("data-page-path", path);
    return component;
  })
    .then((component) => renderComponent(component, newPageContainer, id, action, path,location));
}
//兼容老逻辑保留
window.pcx_page_render = function (params, target,isPageFrame) {
  return pageLoader.menuResolved.then(()=> {
    //底层默认加openerRouter方便和其他框架整合  比如pcx场景 和ui2的shell整合 默认值 为$shell
    addOpenerRoute(params,"$shell");
    let _url = _formatUrl(params);
    if (wx && wx.ServiceUtil && wx.ServiceUtil.addExecutor) _url = wx.ServiceUtil.addExecutor(_url);
    let pageInfo = parsePath(_url);
    return router.resolve(pageInfo,params.currentPage).then((component) => {
      let page;
      if(isPageFrame){
        component = cloneElement(component, {
          ownerPage: params.currentPage,
          ownerModel:params.currentModel,
          renderRoot:target
        });
        page = ReactDOM.render(component, target);
      }else {
        page = ReactDOM.render(React.createElement(
          CompatiblePullDownRefresh, {renderRoot:target}, component
        ), target).page;
      }
      page.renderRoot = target;
      target.setAttribute('route',page.route);
      page.pageId = target.getAttribute('id');
      if(!page.pageId){
        let id = uniqueId('page_' + _url.replace(/\//g, "_"));
        target.setAttribute('id',id);
        page.pageId = id;
      }
      target.page = page;
      let path = pageInfo.pathname;
      path = path.replace(/\/[0-9]+$/, '');
      page.wxPageDeclaration.route = path.replace(/^\//, "");
      params.opener && (page.opener = params.opener);
      /*if (page.onLoad && (typeof page.onLoad == "function")) {
        page.onLoad(page.props.options);
      }*/
      if (page.onShow && (typeof page.onShow == "function")) {
        page.onShow();
      }

      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
      return page;
    });
  });
};
wx.uixPageRender = function (params, target) {
  return pageLoader.menuResolved.then(()=> {
    //底层默认加openerRouter方便和其他框架整合  比如pcx场景 和ui2的shell整合 默认值 为$shell
    addOpenerRoute(params,"$shell");
    let _url = _formatUrl(params);
    if (wx && wx.ServiceUtil && wx.ServiceUtil.addExecutor) _url = wx.ServiceUtil.addExecutor(_url);
    let pageInfo = parsePath(_url);
    return router.resolve(pageInfo,params.currentPage).then((component) => {

      if(params.mode == "page"){
        component = cloneElement(component, {
            configContext:params.configContext
        });
      }else {
        component = cloneElement(component, {
          ownerPage: params.currentPage,
          ownerModel:params.currentModel,
          configContext:params.configContext
        });
      }

      let page = ReactDOM.render(React.createElement(
        CompatiblePullDownRefresh, {renderRoot:target}, component
      ), target).page;
      page.renderRoot = target;
      target.setAttribute('route',page.route);
      page.pageId = target.getAttribute('id');
      if(!page.pageId){
        let id = uniqueId('page_' + _url.replace(/\//g, "_"));
        target.setAttribute('id',id);
        page.pageId = id;
      }
      target.page = page;
      let path = pageInfo.pathname;
      path = path.replace(/\/[0-9]+$/, '');
      page.wxPageDeclaration.route = path.replace(/^\//, "");
      params.opener && (page.opener = params.opener);
      /*if (page.onLoad && (typeof page.onLoad == "function")) {
        page.onLoad(page.props.options);
      }*/
      if (page.onShow && (typeof page.onShow == "function")) {
        page.onShow();
      }

      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
      return page;
    });
  });
};
wx.render = render;

function init() {
  //initConfigProvider();
  offlineRuntime.install();

  //微服务模式，默认首页必须是门户的默认页面，登录后再跳转到期望跳转的页面
  let initUrl = location.hash.substring(1) || mainPagePath;
  window.microService.initUrl = initUrl;
  history.replace(initUrl);

  let currentTheme =  window.getCurrentReactPages?.()?.[0]?.getCurrentTheme?.();
  if(currentTheme){
    ConfigProvider.config({
      theme: currentTheme
    });
  }

  if (!(window.justep && window.justep.Shell && window.justep.Shell.impl)) {
    //不在门户中集成 可以监听history

    render(history.location);
    history.unListen =  history.listen(function ({location, action}) {
      //pc暂时只有预览场景 预览场景 只支持replace 防止body滚动和横向多页面产生冲突
      action= "REPLACE";
      render(location, action);
    });

    if(window.isDebug){
      let modal = null;
      window.addEventListener('error', function(event) {
        if(event?.message === 'ResizeObserver loop limit exceeded' || event?.message === "ResizeObserver loop completed with undelivered notifications.") {
            event.stopPropagation();
            event.stopImmediatePropagation();
        }else{
          console.error(event);
          modal  && modal.destroy();
          modal = Modal.error({
            width:"80vw",
            transitionName:"",
            maskTransitionName:"",
            title:"运行时错误",
            content:<Alert style={{whiteSpace: "pre-line"}} message={event?.message || ""} description={event?.error?.stack || ""}></Alert>
          });
        }
      })
    }


  }
  tabbar.reset();

  window.inited = true;
}

function initConfigProvider(){
  if(wx?.config?.antd){
    ConfigProvider.config(wx?.config?.antd);
  }
}

if (!window.inited) {
  init();
}






